define("discourse/plugins/chat/discourse/lib/init-sidebar-state", ["exports", "discourse/lib/sidebar/panels", "discourse/plugins/chat/discourse/lib/get-user-chat-separate-sidebar-mode"], function (_exports, _panels, _getUserChatSeparateSidebarMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CHAT_PANEL = void 0;
  _exports.initSidebarState = initSidebarState;
  const CHAT_PANEL = _exports.CHAT_PANEL = "chat";
  function initSidebarState(api, user) {
    if (api.getSidebarPanel()?.key === _panels.ADMIN_PANEL) {
      return;
    }
    api.setSidebarPanel(_panels.MAIN_PANEL);
    const chatSeparateSidebarMode = (0, _getUserChatSeparateSidebarMode.getUserChatSeparateSidebarMode)(user);
    if (chatSeparateSidebarMode.fullscreen) {
      api.setCombinedSidebarMode();
      api.showSidebarSwitchPanelButtons();
    } else if (chatSeparateSidebarMode.always) {
      api.setSeparatedSidebarMode();
    } else {
      api.setCombinedSidebarMode();
      api.hideSidebarSwitchPanelButtons();
    }
  }
});